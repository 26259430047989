import React from "react"
import Layout from "../../components/layout-ages"
import { Link } from "gatsby"

export default () => (
  <Layout title="6-7 Year Old">
    <h2>6-7 Year Old</h2>
    <div className="clear-block">
      <div id="node-17" className="node">


        <div className="content clear-block">
          <p><img src="/images/056-cordel-200x267.jpg" className="float-left" width="200"
                  height="267" alt=""/> If your student has started at 3 or 4 year olds and some of the 5 year olds, they may
            be ready to move on to standard music in the five finger level or a little above depending on the child. It
            is wise to use the "<Link to="/books/Moving-On-Book-One">Moving On-One</Link>” with them before making the
            transition to only standard music. It will make it easier for them. If they forget a standard note, simply
            remind them by asking what the note is doing. You might have to give them a little more of the hint at
            first, but in no time at all, they will be playing standard music with as much ease and enjoyment as they
            did the Animal Notes. You have given your student a wonderful gift: a strong foundation in music without
            frustration and their wanting to quit lessons. </p>
          <p>If your child is in this age group and wants to start lessons, the Animal Note method is the best out there
            for them. Trying to distinguish one spot (note) on a music staff from another is so confusing to a child.
            They have nothing in their world to associate with this new information. They are starting from ground zero,
            and it is not easy for them. Once the Animal Note method was developed to the point that it was in book
            form, I never lost a young student who started in our studio and I have started many young children. Almost
            all of our students have continued through their senior year in high school unless they move away. The
            Animal Notes and their word clues take the frustration out of learning the two important basics of music -
            Note Reading and Timing. </p>
          <h3>Testimonial</h3>
          <p><i>"I have started with my six year old and so far so good. He is very interested and proud he can follow
            the music. I am by no means a music teacher but this making it clear how I can get them started."<br/>
            - Stephanie </i></p>
          <p><i>I love your idea of teaching notes with animals and am so happy that you have published something like
            this. Slogans are just too hard for young students to remember and figure out. That's why I have actually
            used animal and other similar concepts in the past for the young ones.<br/>
            - Diana Farias</i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
